<template>
  <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialog" title="采购发货通知明细" custom-class="custom-dialog-max-width" @closed="form = null">
    <template v-if="form">
      <el-form ref="form" :model="form" label-width="68px" label-suffix=":" label-position="right">
        <div class="h sb">
          <form-info-item label="发货单号">{{form.formCode}}</form-info-item>
          <form-info-item label="收货地址">{{form.recProvince}}{{form.recCity}}{{form.recDistrict}}{{form.recAddress}}</form-info-item>
        </div>
        <el-table border :data="form.items" highlight-current-row empty-text="没有商品信息" style="margin: 8px 0;">
          <el-table-column prop="code" label="商品编码" min-width="130" />
          <el-table-column prop="erpCode" label="ERP编码" min-width="130" />
          <el-table-column label="商品" min-width="240">
            <div style="line-height: 1.5;" slot-scope="scope">
              <div>{{scope.row.goodsName}}</div>
              <div class="fc-g">{{$goodsSpecConvert(scope.row.specName)}}</div>
            </div>
          </el-table-column>
          <el-table-column prop="goodsCount" label="数量" align="right" width="80" />
          <el-table-column prop="goodsPrice" label="单价" align="right" width="80" :formatter="$price" v-if="checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY'])"/>
          <el-table-column label="合计" align="right" width="100" :formatter="v=>{ return $price(v.goodsCount*v.goodsPrice)}" v-if="checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY'])"/>
          <el-table-column prop="dependFormCode" label="采购单号" width="120" />
          <el-table-column prop="info" label="备注" min-width="160" />
        </el-table>
        <div class="h">
          <form-info-item label="总金额" v-if="checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY'])">
            <b>{{$price(form.goodsPriceAmount)}}</b>
          </form-info-item>
          <form-info-item label="使用返利金抵扣" label-width="140px" v-if="rebate && checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY'])">
            <b>{{$price(rebate.money)}}</b>
          </form-info-item>
          <div class="flex"></div>
          <el-button @click="dialog = false">关闭</el-button>
        </div>
      </el-form>
    </template>
  </el-dialog>
</template>

<script>
import { getByCode, getRebates } from "@/api/consignment";
import checkPermission from "@/utils/permission";

export default {
  data() {
    return {
      loading: false,
      dialog: false,
      form: null,
      rebate: null
    };
  },
  methods: {
    checkPermission,
    loadDetail(code) {
      this.loading = true;
      getByCode(code)
        .then(res => {
          this.form = res;
          // this.loadRebates();
        })
        .finally(_ => {
          this.loading = false;
        });
    },
    loadRebates() {
      getRebates({
        entId: this.form.entId,
        receiverId: this.form.receiverId,
        erpCode: this.form.erpCode
      }).then(res => {
        this.rebate = res;
      });
    },
    resetForm(form) {
      if (form && form.formCode) {
        if (form.items) {
          this.form = form;
          // this.loadRebates();
        } else this.loadDetail(form.formCode);
        this.dialog = true;
      }
    }
  }
};
</script>
