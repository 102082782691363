<template>
  <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialog" title="使用返利" width="480px" @closed="handleClosed">
    <template v-if="form && rebate">
      <el-form :model="form" :rules="rules" label-position="right" label-width="75px" label-suffix=":">
        <el-form-item label="使用返利" prop="currentPayRebate">
          <div class="h c">
            <price-input v-model="form.currentPayRebate" :max="rebate.maxCanUseRebate / 100" class="flex" />
            <div class="padding-0-10">最高可使用{{$price(rebate.maxCanUseRebate)}}</div>
          </div>
        </el-form-item>
        <el-form-item label="备注" prop="payInfo">
          <el-input type="textarea" v-model="form.payInfo" :maxlength="100" :rows="3" resize="none" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :loading="saving" @click="doSubmit">提交</el-button>
        </el-form-item>
      </el-form>
    </template>
  </el-dialog>
</template>

<script>
import { getByCode, getMaxRebate, payRebate } from "@/api/consignment";

export default {
  data() {
    return {
      dialog: false,
      saving: false,
      loading: false,
      form: null,
      rebate: null,
      rules: {}
    };
  },
  methods: {
    handleClosed() {
      this.form = null;
      this.rebate = null;
    },
    init() {
      getMaxRebate(this.form).then(res => {
        this.rebate = res;
        this.form.currentPayRebate = res.maxCanUseRebate;
        this.form.payInfo = "";
      });
    },
    loadDetail(code) {
      this.loading = true;
      getByCode(code)
        .then(res => {
          this.form = res;
          this.init();
        })
        .finally(_ => {
          this.loading = false;
        });
    },
    load(form) {
      if (form && form.formCode) {
        if (form.items) {
          this.form = form;
          this.init();
        } else this.loadDetail(form.formCode);
        this.dialog = true;
      }
    },
    doSubmit() {
      this.saving = true;
      payRebate(this.form)
        .then(res => {
          this.$notify({
            title: "使用返利成功",
            type: "success",
            duration: 2500
          });
          this.$emit("complete");
          this.dialog = false;
        })
        .finally(_ => {
          this.saving = false;
        });
    }
  }
};
</script>